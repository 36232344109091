import { AppConfig } from '../types/app';

// https://w.amazon.com/bin/view/AWS_Marketing/DSE/MetricsEngineering/AnalyticsSCode/ImplementSCode#HOptionalSteps
const preInitialize = () => {
  var analytics: any = window.AWSMA || {};
  analytics.config = analytics.config || {};
  // TODO: Implement custom SPA page view tracking: https://tasks.awstcprojects.com/browse/ID-1631
  analytics.config.customPageview = true;

  analytics.config.allowedURLParams = ['client_id', 'identity_provider'];

  // ignoredURLParams must have at least one value otherwise s_code will not
  // limit url params based on the allowedURLParams list (known bug in s_code).
  analytics.config.ignoredURLParams = ['state'];

  window.AWSMA = analytics;
};

// TODO: Load s_code script before all other scripts: https://tasks.awstcprojects.com/browse/ID-1632
export const initializeAnalytics = (config: AppConfig) => {
  preInitialize();
  const s_code = document.createElement('script');
  s_code.src = config.adobeAnalyticsSource;
  document.head.append(s_code);
};
