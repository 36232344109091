import React from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import pageTimeOutMessages from '../i18n/pageTimeOut.messages';
import Main from './Main';
import ContainerLayout from './ContainerLayout';
import { AppConfig } from '../types/app';

export const PageTimeOutModal = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Main config={config}>
        <ContainerLayout>
          <Container
            header={
              <Header variant={'h2'}>
                {formatMessage(pageTimeOutMessages.header)}
              </Header>
            }
          >
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.reload();
                    }}
                    data-testid={'refresh-page'}
                    variant={'primary'}
                  >
                    {formatMessage(pageTimeOutMessages.button)}
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="xs">
                <TextContent>
                  {formatMessage(pageTimeOutMessages.containerBody)}
                </TextContent>
              </SpaceBetween>
            </Form>
          </Container>
        </ContainerLayout>
      </Main>
    </>
  );
};
