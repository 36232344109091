import { defineMessages } from 'react-intl';

const pageTimeOutMessages = defineMessages({
  header: {
    id: 'pageTimeOut.header',
    defaultMessage: 'Page time limit exceeded',
    description: 'Modal header',
  },
  containerBody: {
    id: 'pageTimeOut.body',
    defaultMessage:
      'You have reached the page time limit. Please refresh the page and try again.',
    description: 'Modal body',
  },
  button: {
    id: 'pageTimeOut.button',
    defaultMessage: 'Refresh page',
    description: 'Modal button',
  },
});

export default pageTimeOutMessages;
